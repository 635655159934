import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import KingsOfLeonMenu from './_menu.js'

const KingsOfLeon2017TourPage = ({ data }) => (
  <Layout bgClass="clients-kingsofleon">
    <KingsOfLeonMenu pageName="2017_tour" />
    <div className="textPageDetails">
      <p>
        The “Seamless Experience” VIP poster series for Kings of Leon’s 2017 WALLS tour. Nakatomi produced 40 individual posters based around the band’s cherry motif, working with artists all over the country.
      </p>
    </div>
    <div className="gallery-container">
    <NakaLightbox images={data.allFile.edges} />
    </div>
  </Layout>
)

KingsOfLeon2017TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default KingsOfLeon2017TourPage

export const pageQuery = graphql`
  query KingsOfLeon2017TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/kingsofleon/2017_tour/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
