import React from 'react'
import { Link } from 'gatsby'

const KingsOfLeonMenu = (props) => (
  <div>
  <h2 className="major">Kings Of Leon</h2>
  <ul className="bigList">
  <li className={props.pageName === '2017_tour' ? 'current' : ''}><Link to="/clients/kingsofleon/2017_tour">2017 US Tour</Link></li>
  </ul>
  </div>
)

export default KingsOfLeonMenu
